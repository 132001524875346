root {
    /* Link Button */
    --link-underline-gradient-color-left: #ffd600;
    --link-underline-gradient-color-right: #ff5722;
    --link-underline-gradient: linear-gradient(#ffd600, #ff5722);
    --link-underline-width: calc(0% - 0px);

    /* Content Heading Button */
    --heading-text-color: #1d1d1f;
    --heading-text-color-hover: #ff7043;
    --heading-subtext-color: #86868b;
    --heading-subtext-color-hover: #ff7043;

    /* Blog Post */
    --blog-text-color: #1d1d1fff;
    --blog-text-color-hover: #ff7043ff;
    --blog-background: #ffffff00;
    --blog-background-hover: #ffffffff;
    --schedule-a-demo-gradient-color1: #ffd600;
    --schedule-a-demo-gradient-color2: #ff5722;
    --nav-link-content-opacity: 0;
    font-family: 'Fira Sans', sans-serif;
}

nav {
    width: 100%;
    position: relative;
    z-index: 3;
}

nav .nav-active {
    background: black;
}

nav .container {
    width: 100%;
    max-width: 1140px;
    height: 110px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: sticky;
    top: 10px;
    margin: 0 auto;
    padding: 0;
}
nav i {
    width: 16px;
    height: 16px;
}
nav .fa-solid {
    color: #c5c5c7;
}
nav .button-schedule-a-demo>i {
    color: #ffffff;
}
nav .icon-img {
    width: 16px;
    height: 16px;
}
nav a {
    text-decoration: none;
    color: #ffffff;
    transition: 0.4s;
}
nav li {
    list-style: none;
}
nav .nav-logo{
    display: flex;
    padding-left: 1vh;
}

nav .nav-logo a{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav .nav-logo a img{
    width: 220px;
}

nav .nav-links{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

nav .nav-link {
    cursor: pointer;
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}

nav .nav-link > a {
    position: relative;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
    --link-underline-width: calc(0% - 0px);
    --nav-link-content-opacity: 0;
    transition: --link-underline-width 1s ease-out, --nav-link-content-opacity 0.3s;
}

nav .nav-link > a:after {
    content: '';
    position: absolute;
    background-image: linear-gradient(90deg, #ffd600, #ff5722);
    height: 3px;
    width: 0;
    right: 0;
    bottom: -8px;
    transition: 0.5s;
}
nav .nav-link:hover > a:after {
    width: 100%;
    left: 0;
    right: unset;
}
nav .nav-link:hover {
    --link-underline-width: calc(100% - 56px);
    --nav-link-content-opacity: 1;
}
nav .nav-link:hover a {
    font-weight: 500;
    /* line-height: normal; */
}

nav .nav-link:hover .nav-link-content,
nav .nav-link-content:hover {
    opacity: 1;
    visibility: visible;
}
nav .nav-link-content:hover + a {
    --link-underline-width: calc(100% - 56px);
    --nav-link-content-opacity: 1;
}
nav .nav-link > a i {
    display: none;
}
nav .nav-link a:visited{
    text-decoration: none;
    color: #ffffff;
}

nav .nav-link-content {
    position: absolute;
    width: max-content;
    top: 84px;
    display: grid;
    grid-template-areas: "left right";
    opacity: var(--nav-link-content-opacity);
    /* transition: --nav-link-content-opacity 0.3s; */
    transition: all 0.4s ease;
    opacity: 0;
    visibility: hidden;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    filter: drop-shadow(0px 5px 20px rgba(0,0,0,0.5));
}

nav .nav-link-content * {
    margin: 0px;
    padding: 4px 0;
    box-sizing: content-box;
}

nav .nav-link-content h3 {
    font-size: 12px;
    color: #1d1d1f;
    font-weight: 600;
}

nav .nav-link-content a h6 {
    color: #6e6e73;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 0;
    margin-left: 4px;
}

nav .nav-link-content a h6:hover {
    color: #ff7043;
}

nav .nav-link-content-company {
    opacity: 0;
}

nav .nav-link-content-resources {
    grid-template-columns: 1fr 1fr;
    opacity: 0;
}

nav .nav-link-content-product {
    grid-template-columns: 1fr 1fr;
    opacity: 0;
}

nav .nav-link-content-one {
    padding: 20px 30px;
    grid-area: left;
    background-color: #fbfbfd;
    border-radius: 10px;
    min-width: 208px;
}

nav .nav-link-content-left {
    padding: 20px 30px;
    grid-area: left;
    background-color: #fbfbfd;
    border-radius: 10px 0 0 10px;
    min-width: 208px;
}

nav .nav-link-content-right {
    padding: 20px 30px;
    grid-area: right;
    background-color: #f2f2f4;
    border-radius: 0 10px 10px 0px;
    min-width: 208px;
}

nav .nav-link-content-left ul,
nav .nav-link-content-right ul{
    display: flex;
    flex-direction: column;
}

nav .nav-link-content-left ul:nth-child(2) {
    padding-bottom: 24px;
}

nav .heading {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1f;

}
nav .heading:hover {
    color: #ff7043 !important;
}
nav .subtext {
    font-size: 10px;
    font-weight: 400;
    color: #86868b;
    padding-top: 0 !important;
    padding-bottom: 4px;
}
nav .subtext:hover {
    color: #ff7043;
}
nav .nav-link ul li a{
    display: grid;
    grid-template-columns: 20px 1fr;
}
nav .nav-link.schedule-a-demo-mobile {
    display: none;
}

nav .nav-link-content .our-blog a {
    color: #1d1d1f;
}
nav .nav-link-content .our-blog > h3 a {
    font-size: 14px;
    color: #1d1d1f;
    font-weight: 600;
}
nav .nav-link-content .our-blog > h3 a:hover {
    color: #ff7043;
}
nav .nav-link-content .our-blog > h3 i {
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.6);
    padding: 8px;
    margin-right: 8px;
    border-radius: 4px;
    color: #ff7043;
}
nav .box-shadow-radius {
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.6);
    padding: 8px;
    margin-right: 12px;
    border-radius: 4px;
}
nav img.box-shadow-radius {
    width: 16px;
    height: 16px;
}
nav .nav-link-content .our-blog > h5 {
    color: #1d1d1f;
    text-transform: uppercase;
    margin: 10px 0 0 0;
}

nav .post * {
    box-sizing: border-box;
}

nav .nav-link-content .post a {
    display: block;
}
nav .nav-link-content .post a .body {
    padding: 6px 10px;
    border-left: #ff8f00 solid 2px;
}
nav .nav-link-content .post a:hover .body {
    background-color: #fbfbfd;
    border-left: #ff7043 solid 2px;
}
nav .nav-link-content .post a:hover .desc {
    color:#ff7043;
}
nav .nav-link-content .post .pill {
    font-size: 10px;
    color: #ff7043;
    background-color: #ffddb2;
    display: inline-block;
    border-radius: 30px;
    padding: 4px 12px;
}
nav .nav-link-content .post .desc {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1f;
}

nav h5{
    font-size: 14px;
    color: #6e6e73;
    font-weight: bold;
}

nav h6{
    font-size: 12px;
    font-weight: 600;
    color: #1d1d1f;
}
nav h6:hover{
    color: #ff7043;
}
nav .nav-user {
    display: flex;
    min-width: 200px;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
}

nav .nav-user a {
    padding: 8px 10px 6px;
    text-align: center;
    color: #ffffff;
}
nav .nav-user .button-log-in {
    margin-right: 16px;
}

nav .button-schedule-a-demo {
    font-size: 16px;
    border: 2px solid #ffffff;
    border-radius: 6px;
    position: relative;
    transition: all 0.5s ease;
}

nav .button-schedule-a-demo:before {
    content: '';
    position: absolute;
    background-image: linear-gradient(90deg, #ffd600, #ff5722);
    width: 0;
    right: 0;
    bottom: 0px;
    transition: 0.5s;
    top: 0;
    z-index: -1;
}

nav .button-schedule-a-demo:hover:before {
    width: 100%;
    left: 0;
    right: unset;
}

nav img {
    max-width: 100%;
}

nav .resources-left-documentation > a > i {
    float: left;
    color: #ff7043;
}
nav .resources-left-documentation > a h5 {
    padding: 0;
}
nav .resources-left-documentation > a > div {
    margin: 0 0 0 40px;
    padding: 0;
}

nav .resources-right > section,
nav .resources-left > section {
    display: flex;
    gap: 30px;
}

nav .resources-right-wrapper {
    margin-top: -4px;
    padding: 0;
}

nav .resources-left-documentation a > div h5,
nav .resources-left-documentation a > div span {
    transition: all 0.1s ease;
}
nav .resources-left-documentation a:hover > div h5,
nav .resources-left-documentation a:hover > div span {
    color: #ff7043;
}

nav .products-product > a > i {
    float: left;
    color: #ff7043;
}
nav .products-product > a h5 {
    padding: 0;
}
nav .products-product > a > div {
    padding: 0;
}

nav .product-right > section,
nav .product-left > section {
    display: flex;
    gap: 30px;
}

nav .product-right-wrapper {
    margin-top: -4px;
    padding: 0;
}

nav .products-product a {
    display: inline-flex;
}

nav .products-product a > div {
    flex-direction: column;
}

nav .products-product a > div h5,
nav .products-product a > div p {
    transition: all 0.1s ease;
}
nav .products-product a:hover > div h5,
nav .products-product a:hover > div p {
    color: #ff7043;
}

nav .nav-hamburger {
    display: none;
}

nav .hide {
    display: none !important;
}

nav i.external-link-icon {
    color: #86868b;
    font-size: 10px;
    padding-left: 2px;
}

nav i.external-link-icon--heading {
    color: #1d1d1f;
    font-size: 10px;
    padding-left: 2px;
}

nav .resources-right-wrapper * {
    box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    nav {
        position: relative;
    }
    nav .container {
        max-width: 100%;
        height: 80px;
        top: 0;
    }
    nav .nav-logo {
        /* padding-left: 30px; */
        padding-left: 20px;
    }
    nav .nav-logo a img {
        max-width: 240px;
    }
    nav .nav-hamburger {
        display: flex !important;
        align-items: center;
    }
    nav .nav-hamburger i {
        color: white;
        font-size: 24px;
        /* margin-right: 30px; */
        margin-top: -8px;
    }
    nav .nav-hamburger i.fa-xmark {
        display: none;
    }
    nav .nav.open .nav-hamburger i.fa-xmark {
        display: inline-block;
    }
    nav .nav.open .nav-hamburger i.fa-bars {
        display: none;
    }
    nav .nav.open .nav-link-content {
        padding-left: -30px;
        padding-right: -30px;
    }

    nav .nav-links,
    nav .nav-user {
        display: none;
    }
    nav .nav-links {
        position: absolute;
        left: 0;
        right: 0;
        background-color: #fbfbfd;
        top: 80px;
        height: 100vh;
        overflow: scroll;
    }
    nav .nav-link {
        display: block;
    }
    nav .nav-link-underline {
        display: none;
    }
    nav .nav-link a,
    nav .nav-link a:visited {
        color: black;
        display: block;
        font-weight: 600;
    }
    nav .nav-link > a:after {
        display: none;
    }
    nav .nav-link > a i.fa-angle-left {
        display: inline-block;
        color: #1d1d1f;
    }
    nav .nav-link > a i.fa-chevron-right {
        display: block;
        float: right;
        color: #1d1d1f;
        margin-top: 10px;
    }
    nav .nav-link.open .nav-link-content {
        display: block;
        visibility: visible;
        opacity: 1;
    }
    nav .nav-link .fa-angle-left {
        display: none !important;
    }
    nav .nav-link.open .fa-angle-left {
        display: inline-block !important;
        margin-right: 23px;
    }
    nav .nav-link.open .fa-chevron-right {
        display: none !important;
    }
    .nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
        background-color: #fbfbfd;
    }
    nav.open .nav-links {
        display: block;
        padding-top: 10%;
    }
    nav.open .nav-logo img {
        content: url('/assets/images/journeyapps-logo/journeyapps-logo-light-bg.svg');
    }
    nav.open .container {
        background-color: #fbfbfd;
    }
    nav.open i.fa-xmark {
        color: #000;
        display: inline-block;
    }

    nav .nav-link-content {
        position: static;
        display: none;
        width: 100%;
        max-width: 100%;
        margin-left: -30px;
        margin-right: -30px;
        box-shadow: none;
        filter: none;
    }
    nav .nav-link-content-left {
        border-radius: 0;
    }
    nav .nav-link-content-right {
        border-radius: 0;
    }
    nav .nav-link-content h3 {
        font-size: 14px;
    }
    nav .nav-link-content.open {
        display: block;
    }
    nav .nav-link-content > div {
        /* padding: 20px; */
        width: 100%;
        max-width: 100%;
    }

    nav .nav-link-content .post .image {
        height: 68px;
    }
    nav .nav-link-content .post .desc {
        font-size: 12px;
    }

    nav .nav-link.schedule-a-demo-mobile {
        display: block;
        padding: 20px 30px;
        margin-top: 100px;
    }
    nav .nav-link.schedule-a-demo-mobile a {
        background-image: linear-gradient(to right, #fcb116 0%, #ff6f00 100%);
        box-shadow: none;
        border: none;
        border-radius: 8px;
        padding: 14px;
        color: white;
        text-align: center;
    }
    .nav-link.open a.nav-button-products,
    .nav-link.open a.nav-button-solutions,
    .nav-link.open a.nav-button-company,
    .nav-link.open a.nav-button-resources {
        position: relative;
        margin-bottom: 10px;
    }
    .nav-link.open a.nav-button-products::after,
    .nav-link.open a.nav-button-solutions::after,
    .nav-link.open a.nav-button-company::after,
    .nav-link.open a.nav-button-resources::after {
        content: '';
        width: 120%;
        height: 1px;
        border-bottom: 1px solid #ccc;
        display: block;
        background-image: none;
        margin-right: -30px;
        top: 36px;
    }

    .resources-left > section,
    .resources-right > section {
        flex-direction: column;
    }

    nav .products-product a,
    nav .products-product a:visited {
        display: flex;
    }
}